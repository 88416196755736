import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import adminService from "./admin-service";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      products: [],
      productList: [],
      cartList: [],
      search: "",
      showAddToCart: true,
      cartId: "",
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  async mounted() {
    let partObj = {
      user_id: this.userId,
      SearchStr: "",
      class_keys: []
    }
    this.getProductList(partObj)
    let res = await adminService.getCartList("get", this.userId, false)
    console.log(res)
    if (res?.length > 0) {
      this.showAddToCart = false;
      this.cartId = res[0]?.cart_id;
      this.cartList = res?.map((x) => ({
        part_id: x.part_key,
        Part_Number: x.part_num,
        img: x.img,
        Description: x.part_desc,
        qty: x.qty,
        available_qty: x.available_qty,
        cart_line_key: x.cart_line_id
      }))
    }


  },
  methods: {
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    actionSetter(action) {
      return Utility.actionSetter(this.actionList, action)
    },

    async refreshCartList() {
      let resp = await adminService.getCartList("get", this.userId, false);
      console.log(resp)
      this.cartId = resp[0]?.cart_id;
      this.cartList = resp?.map((x) => ({
        part_id: x.part_key,
        Part_Number: x.part_num,
        img: x.img,
        Description: x.part_desc,
        qty: x.qty,
        available_qty: x.available_qty,
        cart_line_key: x.cart_line_id
      }));
      console.log(this.cartList);
    },
    async updateCart(product, passType) {
      const existingCartItem = this.cartList.find(
        (cartItem) => cartItem.part_id === product.part_id
      );

      let cartlines = [{
        cart_line_key: existingCartItem ? existingCartItem.cart_line_key : 0,
        part_key: product.part_id,
        qty: product.qty
      }];

      let updateObj = {
        user_id: this.userId,
        pass: passType,
        cart_key: this.cartId,
        cartlines: cartlines
      };

      let submitObj = { json: JSON.stringify(updateObj) };
      let res;

      if (passType === 1) {
        res = await adminService.postCartUpdate("post", submitObj, true);
      } else {
        res = await adminService.postCartUpdate("post", submitObj, true);
      }

      if (res) {
        await this.refreshCartList();
      }
    },

    async modifyCart(product, actionType) {
      const existingCartItem = this.cartList.find(
        (cartItem) => cartItem.part_id === product.part_id
      );

      if (actionType === 'increase') {
        if (this.products[product.index].qty < this.products[product.index].available_qty) {
          this.products[product.index].qty++;
          if (this.cartId) {
            if (existingCartItem) {
              await this.updateCart(this.products[product.index], 2); // Update existing item (pass=2)
            } else {
              await this.updateCart(this.products[product.index], 1); // Add new item (pass=1)
            }
          } else {
            await this.addToCart(this.products[product.index]);
          }
        }
      } else if (actionType === 'decrease') {
        if (this.products[product.index].qty > 0) {
          this.products[product.index].qty--;

          if (this.cartId) {
            if (this.products[product.index].qty === 0) {
              await this.updateCart(this.products[product.index], 3); // Remove item (pass=3)
            } else {
              await this.updateCart(this.products[product.index], 2); // Update existing item (pass=2)
            }
          }
        }
      }
    },

    async addToCart(product) {
      const existingCartItem = this.cartList.find(
        (cartItem) => cartItem.part_id === product.part_id
      );

      if (!existingCartItem) {
        let cartlines = [{
          cart_line_key: 0,
          part_key: product.part_id,
          qty: product.qty
        }];

        let updateObj = {
          user_id: this.userId,
          pass: 1,
          cart_key: this.cartId,
          cartlines: cartlines
        };

        let submitObj = { json: JSON.stringify(updateObj) };
        let res = await adminService.postPlaceOrder("post", submitObj, true);

        if (res?.Message) {
          await this.refreshCartList();
          this.showAddToCart = false;
        }
      }
    },
    // Replace increaseQuantity and decreaseQuantity with the new modifyCart function
    async increaseQuantity(product, index) {
      await this.modifyCart({ ...product, index }, 'increase');
    },

    async decreaseQuantity(product, index) {
      await this.modifyCart({ ...product, index }, 'decrease');
    },


    searchProduct(text) {
      this.search = text?.toUpperCase();
      if (text?.length > 2) {
        let partObj = {
          user_id: this.userId,
          SearchStr: text,
          class_keys: []
        }
        Utility.debounce(() => {
          this.getProductList(partObj);
        }, 700)();
      }

    },
    async getProductList(obj) {
      let submitObj = { json: JSON.stringify(obj) }
      let searchResult = await adminService.postProductList("post", submitObj, false)
      if (searchResult?.length > 0) {
        searchResult = searchResult?.map(v => ({ ...v, img: "https://t4.ftcdn.net/jpg/01/27/53/71/360_F_127537153_k1y9h4hUoAmbNXs6QlgWkCBd87qWwp2X.jpg" }))
        this.products = searchResult
      }
      else {
        this.products = []
      }
      console.log(this.products)
    }
  },


  components: {
    breadcrumbComp,
  }
};